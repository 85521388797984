<template>
  <div id="app"> 
  <PageLoader/>
  <TheNavigation />
    
    <div class="content" :class="{'shrink':showNav}">
      
      <router-view>
        <div id="navigation-icon">
         
          <b-button class="btn btn-nav" >
          <svg @click.stop="showNav = !showNav"
              width="40"
              height="40"
              viewBox="0 0 32 42"
              xmlns="http://www.w3.org/2000/svg"
              onclick="this.classList.toggle('active')"
            >
              <g transform="matrix(1,0,0,1,-438.286,-264.004)">
                <g id="arrow_left1">
                  <g
                    transform="matrix(-1,-1.22465e-16,1.22465e-16,-1,858.787,564.935)"
                  >
                    <path
                      id="top"
                      d="M390,270L420,270L420,270C420,270 420.195,250.19 405,265C389.805,279.81 390,279.967 390,279.967"
                    />
                  </g>
                  <g transform="matrix(-1,-2.44929e-16,-2.44929e-16,1,858.786,5)">
                    <path
                      id="bottom"
                      d="M390,270L420,270L420,270C420,270 420.195,250.19 405,265C389.805,279.81 390,279.967 390,279.967"
                    />
                  </g>
                  <g
                    transform="matrix(-1,-1.22465e-16,1.22465e-16,-1,858.787,569.935)"
                  >
                    <path id="middle" d="M390,284.967L420,284.967" />
                  </g>
                </g>
              </g>
            </svg>
          </b-button>
          
        </div>
      </router-view>
    </div>    
    
  </div>
</template>
<script>
  import TheNavigation from './components/TheNavigation.vue'
  import PageLoader from './view/PageLoader.vue'
 
  export default {
    name: 'App',
    data: () => {
      return {
              showNav: false,
             
        
            
        }
    },
    components: {
      TheNavigation,
      PageLoader
    },
     
    computed:{
       
        init() {
          return this.$store.state.init;
        },
        errors() {
          return this.$store.state.errors;
        },




    },
    methods:{
       getUserId(){
			 let userId = localStorage.getItem("userId")
			 
          	const params = {
            userId: userId,

          }
          const data = Object.keys(params)
                  .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                  .join('&');

          const options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/x-www-form-urlencoded',
                        'vmiadmin-authz': localStorage.getItem("tokenId")  },
                    url: '/ax/getPermissionsByUser',
					data
                    };

				this.axios(options)
				.then((response) => { 
				
					response.data.forEach((value) => {
						
						if(value == "FXADDCUSTOMER"){
							this.init.FXADDCUSTOMER = true;
						}
						if(value == "FXADMIN"){
							this.init.FXADMIN= true;
						}
						if(value == "FXAPPROVERONE"){
							this.init.FXAPPROVERONE= true;
						}
						if(value == "FXAPPROVERTWO"){
							this.init.FXAPPROVERTWO= true;
						}
						if(value == "FXCOMPLIANCE"){
							this.init.FXCOMPLIANCE= true;
						}
						// if(value == "FXCOMPLIANCECHECKER"){
						// 	this.init.FXCOMPLIANCECHECKER= true;
						// }
						if(value == "FXCOMPLIANCEMAKER"){
							this.init.FXCOMPLIANCEMAKER= true;
						}
						if(value == "FXCREATECUSTOMER"){
							this.init.FXCREATECUSTOMER= true;
						}
            if(value == "FXCUSTSEARCH"){
							this.init.FXCUSTSEARCH= true;
						}
						if(value == "FXDOCUMENTS"){
							this.init.FXDOCUMENTS= true;
						}
						if(value == "FXNAMESCREEN"){
							this.init.FXNAMESCREEN= true;
						}
						if(value == "FXPENDINGSIGNUPS"){
							this.init.FXPENDINGSIGNUPS= true;
						}
						if(value == "FXSUPERADMIN"){
							this.init.FXSUPERADMIN= true;
						}
						if(value == "FXTRANSACTION"){
							this.init.FXTRANSACTION= true;
						}
            if(value == "FXCUSTARCHIVE"){
							this.init.FXCUSTARCHIVE= true;
						}
            if(value == "FXCUSTVIEW"){
							this.init.FXCUSTVIEW= true;
						}
						if(value == "FXDELETE"){
							this.init.FXDELETE= true;
						}
						if(value == "FXCUSTEDIT"){
							this.init.FXCUSTEDIT= true;
						}
            if(value == "FXCUSTAPPLICATION"){
							this.init.FXCUSTAPPLICATION= true;
						}

            if(value == "FXCUSTUSERS"){
							this.init.FXCUSTUSERS= true;
						}
            if(value == "FXCUSTTRANSACTIONS"){
							this.init.FXCUSTTRANSACTIONS= true;
						}
            if(value == "FXCUSTISSUECERTIFICATE"){
							this.init.FXCUSTISSUECERTIFICATE= true;
						}
						if(value == "FXCUSTPAYMENT"){
							this.init.FXCUSTPAYMENT= true;
						}
						if(value == "FXSIGN"){
							this.init.FXSIGN= true;
						}
            if(value == "FXPENDINGALERTS"){
							this.init.FXPENDINGALERTS = true;
						}
					})
        })
        },
           
    },
    beforeMount(){
        
        
    },
  
    watch: {
      $route() {
        this.showNav = false;   
        this.getUserId();
      },
      
    }
  }
</script>

<style lang="scss">
* {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    padding: 0;
  
    background: white !important;
    width: 100%;
    overflow-x: hidden;

  }
  .modal-header {
  background-image: linear-gradient(
            to bottom,
            #5a5f71 29%,
            #10121C 88% );
    margin-right:0px;
    font-size:25px;
    display:flex;
    justify-content:space-between;
}

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    font-size: 2rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;    
  }
  .options{
    width: 100%;
}
.options button{
    width: 170px;
    padding: 10px;
    margin: 5px;
    border: none !important;
    background-image: linear-gradient(135deg,#007bff,#007bff 20%,#007bff 70%,#007bff)!important;
    color: rgb(11, 11, 11) !important;
    border-radius: 10px;
    border: 1px #007bff solid !important;
    
}
.options button:hover{
    background-image: linear-gradient(135deg,#007bff,#007bff 20%,#007bff 70%,#007bff)!important;
    border: 1px #007bff solid !important;
   
}
.options button.active{
    background-color:#007bff !important;
    border: 1px #007bff solid !important;
   
}
.spinner {
    animation: spin 2s linear infinite;
  }
  #navigation-icon > i {
    cursor: pointer;
      
   
  }

  svg {
        fill-rule: evenodd;
        clip-rule: evenodd;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-miterlimit: 1.5;
        cursor:pointer;
      }

      svg path {
        fill: none;
        stroke: white;
        stroke-width: 1px;
      }

      svg + svg {
        margin-left: 1.5rem;
      }

  

      .icons {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
      }

      #top,
      #bottom {
        stroke-dasharray: 30, 75.39;
        transition: all 0.6s cubic-bezier(0.6, 0.33, 0.67, 1.29);
      }

      svg.active #top,
      svg.active #bottom {
        stroke-dasharray: 75.39;
        stroke-dashoffset: -60;
      }

      svg:nth-child(2) {
        transform: rotate(0deg);
        transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
      }

      svg:nth-child(2) path {
        transition: all 0.4s ease-in-out 0.6s;
      }

      svg:nth-child(2).active {
        transform: rotate(180deg);
      }

      .rocket #top {
        stroke-dasharray: 30, 88;
      }

  .btn-nav{
    background-image: linear-gradient(135deg,#007bff,#007bff 20%,#007bff 70%,#007bff)!important;
  }


   .icon {
      padding: 10px 16px;
      cursor: pointer;
   }
    .icon-circle {
      width: 32px;
      height: 32px;
      padding: 5px;
      margin-left: 10px;
      cursor: pointer;
      color: #222;
      background-image: linear-gradient(135deg,#007bff,#007bff 20%,#007bff 70%,#007bff)!important;
      border-radius: 50%;
    }
   
      

  .content {
    position: absolute;
    top: 0;
    width: 100vw;
    min-height: 100vh;
    padding: 16px;
    transition: .3s all ease-in-out;
  
    background:white;
  }
 

  .shrink {
    transform: scale(.9) translateX(250px);
    border-radius: 20px;    
    height: 100vh;
    overflow: hidden;
    box-shadow: -5px 5px 20px #333;
    cursor: pointer;
  }
 

  @import '@/assets/css/_reset';

  @font-face {
    font-family: 'Montserrat-Regular';
    src: url(./assets/font/Montserrat-Regular.otf);
    font-style: normal;
  }


  #app {
    font-family: 'Montserrat-Regular';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #222;
  }

label, span{
    display: inline-block;
    margin-bottom: 0.5rem;
    color: black;
    font-size: 20px;
}

.h4, h4 {
    font-size: 1.5rem;
}
  *{
    box-sizing: border-box;
  }

  html {
    margin: 0 !important;
    padding: 0 !important;
    scroll-behavior: smooth;
  }
  
  .form-group {
    margin-bottom: 1rem;
    display: block;
    justify-content: center !important;


   
    .form-control {
      display: block;
      margin: 0px 5px;
      width: 100%;
      height: calc(1.5em + 0.75rem + 2px);
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      line-height: 1.5;
      color: #495057;
      background-color: #dedce3;
      background-clip: padding-box;
      border: 1px solid #dedce3;
      /* border-radius: 10px;; */
      outline: none;
    }
  }
  .alert { 
    padding: 0.5em;
    color: #FFF;
    margin-bottom: 1em;
  }
  .alert .alert-info {
    background-color: #3498db !important;
  }

  .alert-success {
    background-color: #a8c1a6 !important;
  }
  .alert-danger {
      color: #a94442 !important; 
    }
  .btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    cursor: pointer;
  }
  .help-block {
    color: #e74c3c;
    font-size: 0.95em;
    margin-top: 0.25em;
    
  }
 
  .btn-main{
        background-size:0% 100%;
        background-repeat:no-repeat;
        width:100%;
        border-radius: 10px;
        color:black !important;
        border:1px solid #007bff;
      }
  .btn-main:hover{
      background-image: linear-gradient(135deg,#007bff,#007bff 20%,#007bff 70%,#007bff)!important;
	    background-size:100% 100%;
    	color:black !important;
      border:1px solid #007bff;
  }
  .btn-main:active{
    color:white !important;
  }
 .active{
  color: white !important;
 }
  .coin{
    width: 2rem;
  }

.danger{
  color: red;
  font-size: 1rem;;
}
.modal-close {
  position: absolute;
  left: 1em;
  cursor: pointer;  
  svg {
        transform: scale(1.25);
  }
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.loading-spinner {
      border: 4px solid rgba(0, 0, 0, 0.3);
      border-top: 4px solid #007BFF; /* Change the color to your preferred color */
      border-radius: 50%;
      width: 40px;
      height: 40px;
      animation: spin 1s linear infinite;
      margin: 0 auto;
    }
    @keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  border-radius: 8px !important;
  overflow-y: auto;
}
.flex-start{
  display: flex;
  justify-content: flex-start !important;
  padding: 10px;
  }

.flex-end{
  display: flex;
  justify-content: flex-end !important;
  img{
    width: 2rem;
  }
 
}

.modal-container {
  width: 500px;
  margin: 10px auto;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 700px; 
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: 'Montserrat-Regular', sans-serif !important; 
}
.modal-container-sm { 
  width: 300px;
  overflow-y: auto;
}

.modal-header {
  position: relative;
  padding: 1em;
  text-align: center;
  display: flex;
   align-items: center;
  justify-content: center;
   background-image: linear-gradient(#2b70d1, #4a94f5);
   border-radius: 6px !important;
   color: white;
}

.qr-content .qrCanvas{
  display: block;
  justify-content: center !important;
  margin: 0 auto !important;
}

.modal-body {
  margin: 20px 0;
  padding: 1em;
  overflow-y: auto !important;
}

.modal-default-button {
  float: right;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

a:hover{
  color: #817a71 !important; 
}

  .close {
    float: right;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1;
      color: #515151;
      text-shadow: 0 1px 0 #fff;
      opacity: .5;
      background-color: transparent;
      border: 0;
      -webkit-appearance: none;
      padding: 1rem;
      margin: -1rem -1rem -1rem auto;
          cursor: pointer;
  }
 

  .input-group-prepend {
        margin-right: -1px;
  }

  .input-group-append, .input-group-prepend {
    display: flex;
  }

  .input-group.has-validation>.input-group-append:nth-last-child(n+3)>.btn, .input-group.has-validation>.input-group-append:nth-last-child(n+3)>.input-group-text, .input-group:not(.has-validation)>.input-group-append:not(:last-child)>.btn, .input-group:not(.has-validation)>.input-group-append:not(:last-child)>.input-group-text, .input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle), .input-group>.input-group-append:last-child>.input-group-text:not(:last-child), .input-group>.input-group-prepend>.btn, .input-group>.input-group-prepend>.input-group-text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .input-group-append .btn, .input-group-prepend .btn {
    position: relative;
    z-index: 2;
  }
  .input-group>.custom-select:not(:first-child), .input-group>.form-control:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .input-group>.custom-file, .input-group>.custom-select, .input-group>.form-control, .input-group>.form-control-plaintext {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;
  }

  .input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}

.input-group>.input-group-append>.btn, .input-group>.input-group-append>.input-group-text, .input-group>.input-group-prepend:first-child>.btn:not(:first-child), .input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child), .input-group>.input-group-prepend:not(:first-child)>.btn, .input-group>.input-group-prepend:not(:first-child)>.input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group.has-validation>.custom-file:nth-last-child(n+3) .custom-file-label, .input-group.has-validation>.custom-file:nth-last-child(n+3) .custom-file-label:after, .input-group.has-validation>.custom-select:nth-last-child(n+3), .input-group.has-validation>.form-control:nth-last-child(n+3), .input-group:not(.has-validation)>.custom-file:not(:last-child) .custom-file-label, .input-group:not(.has-validation)>.custom-file:not(:last-child) .custom-file-label:after, .input-group:not(.has-validation)>.custom-select:not(:last-child), .input-group:not(.has-validation)>.form-control:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}


  .text-white {
      color: black !important;
  }

  .text-center {
    text-align: center!important;
}
h1{
  font-size: 2rem;
}
h2{
  font-size: 1.8rem;
}
h3{
  font-size: 1.6rem;
}
h4{
  font-size: 1.4rem;
}
h5{
  font-size: 1.2rem;
}
h6, td, label, span, .label, .span, input, button{
  font-size: 1rem !important;
}
p, .alert,.country-name span{
  font-size: 0.8rem !important;
}


 @media screen and (max-width: 700px) {
 h6, td, label, span, .label, .span{
  font-size: 0.75rem;
  }
  p, .alert{
    font-size: 0.75rem !important;
  }
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }
  .navbar li{
            font-size: 20px;
        }
  .sidebar a {float: left;}
  div.content {margin-left: 0;}
}

@media screen and (max-width: 400px) {
  h6, td, label, span, .label, .span{
  font-size: 0.5rem;
  }
  p, .alert{
    font-size: 0.45rem !important;
  }
  .navbar li{
            font-size: 10px;
        }
  .sidebar a {
    text-align: center;
    float: none;
  }
}

</style>
