<template>
  <div class="navigation">
    <ul>
			<p class="label text-white">My Wallet</p>
			<div class="card">
					<div class="container-fluid">
						
					</div>
					
				</div>
				<ul class="sidebar">
				</ul>
					<li ><router-link  to="/"><span class="nav-icon"></span>Dashboard</router-link></li>
					<!-- <li ><router-link  to="/compliance-dashboard"><span class="nav-icon"></span>Compliance Dashboard</router-link></li> -->
					<p class="mb-2 text-muted">Data Management</p>
					<li v-if="init.FXCUSTSEARCH" v-b-tooltip.hover title="Click to view and update Customer's profile"><router-link  to="/customer-search"><span class="nav-icon"></span>Customer Search</router-link></li>
					<li v-if="init.FXTRANSACTION" v-b-tooltip.hover title="Click to view and monitor Customer's transactions"><router-link  to="/transactions"><span class="nav-icon"></span>Transaction Search</router-link></li>
					<li v-if="init.FXDOCUMENTS" v-b-tooltip.hover title="Click to view and manage customer's uploaded documents"><router-link  to="/documents"><span class="nav-icon"></span>Manage Documents</router-link></li>
					<li v-if="init.FXPENDINGSIGNUPS" v-b-tooltip.hover title="Click to view and manage pending signups"><router-link  to="/pending-signup"><span class="nav-icon"></span>Pending Signups</router-link></li>
					<!-- <li v-if="init.FXCREATECUSTOMER"><router-link  to="/create-customers"><span class="nav-icon"></span>Create Customers</router-link></li> -->
					<li v-if="init.FXCUSTSEARCH" v-b-tooltip.hover title="Click to view and monitor all customers' logs"><router-link to="/pending-alerts" ><span class="nav-icon"></span>Customer Logs</router-link></li>
					<li v-if="init.FXCUSTSEARCH" v-b-tooltip.hover title="Click to view and monitor all customers who started the integration"><router-link to="/integration-logs" ><span class="nav-icon"></span>Integration Logs</router-link></li>
					
					<hr>
					<p class="mb-2 text-muted">Due Diligence</p>
					<li v-if="init.FXCOMPLIANCE" v-b-tooltip.hover title="Click to view customers' AML reports"><router-link  to="/aml"><span class="nav-icon"></span>AML Report</router-link></li>
					<li v-if="init.FXNAMESCREEN" v-b-tooltip.hover title="Click to screen customers' names and company's"><router-link  to="/namescreen"><span class="nav-icon"></span>Namescreen Report</router-link></li>
					<!-- <li v-if="init.FXWATCHLIST"><router-link  to="/watchlist"><span class="nav-icon"></span>Watchlist</router-link></li> -->
					<li v-if="init.FXCOMPLIANCE" v-b-tooltip.hover title="Click to view and update compliance settings"><router-link  to="/compliance"><span class="nav-icon"></span>Compliance Settings</router-link></li>
					<hr>
					<p class="mb-2 text-muted">Admin Settings</p>

					<li v-if="init.FXADMIN" v-b-tooltip.hover title="Click to view and audit admin users' logs"><router-link to="/admin-logs"><span class="nav-icon"></span>Admin Logs</router-link></li>
					<li v-if="init.FXSUPERADMIN || init.FXADMIN" v-b-tooltip.hover title="Click to view and manage admin roles, functions and users."><router-link  to="/admin-users"><span class="nav-icon"></span>Admin Users</router-link></li>
					<li v-if="init.FXSUPERSYSTEMADMIN" v-b-tooltip.hover title="Click to manage system's settings"><router-link  to="/refdata"><span class="nav-icon"></span>System Settings</router-link></li>
					<hr>
					<div></div> 
					<li><router-link  to="/certificates"><span class="nav-icon"></span>Certificates</router-link></li>				
					<li class="text-dark" @click="logout">Logout</li>
    			</ul>

	
	
	<Logout/>
  </div>
</template>

<script>
import numeral from 'numeral' 
import PinKey from '@/components/modal/PinKey.vue'
import Logout from '../components/modal/Logout.vue'

export default {
	
	name: 'TheNavigation',
	components: {
		PinKey,
		Logout,
	},
	data: function() {
		return  {
			successGenerate: false,
			
			


			
		}
	},
	computed: {
	      	init() {
	        	return this.$store.state.init;
	      	},
			show() {
	        	return this.$store.state.show;
	      	},
	    },
	methods: {
		
		formatAmount: function(num){
		  return numeral(num).format('0,0.00000000');
		},
		
		send(){
            // this.init.show.modalSend = true
			window.location.href="/#/send"
        },
		deposit(){
            //this.init.show.modalReceive = true
			window.location.href="/#/receive"
        },
		goToHistory(){
           window.location.href = "/#/history";
        },
		 logout: function() {
          this.$bvModal.show('logout-modal'); 

        
          },
		
		 checkSession: function() {
				const options = {
				method: 'POST',
				headers: { 'content-type': 'application/x-www-form-urlencoded', 'vmiadmin-authz': localStorage.getItem("tokenId")  },
				url: '/ax/sessionCheck',
				};

				this.axios(options)
				.then((response) => { 
                
					
					}).catch((err) => {
						
					if(err.response.data.msgCode == '00001') {
						window.location.href = "/#/login";
					}  

					if(err.response.data.msgCode == '00020') {
						setTimeout( () => {
						window.location.href = "/#/setup-profile";
                        
					},1000)
						
					}  

					})
	},
		getUserId(){
			 let userId = localStorage.getItem("userId")
			 
          	const params = {
            userId: userId,

          }
          const data = Object.keys(params)
                  .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                  .join('&');

          const options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/x-www-form-urlencoded',
                        'vmiadmin-authz': localStorage.getItem("tokenId")  },
                    url: '/ax/getPermissionsByUser',
					data
                    };

				this.axios(options)
				.then((response) => { 
				
					response.data.forEach((value) => {
						
						if(value == "FXADDCUSTOMER"){
							this.init.FXADDCUSTOMER = true;
						}
						if(value == "FXADMIN"){
							this.init.FXADMIN= true;
						}
						
						if(value == "FXCOMPLIANCE"){
							this.init.FXCOMPLIANCE= true;
						}
					
						if(value == "FXCREATECUSTOMER"){
							this.init.FXCREATECUSTOMER= true;
						}
            			if(value == "FXCUSTSEARCH"){
							this.init.FXCUSTSEARCH= true;
						}
						if(value == "FXDOCUMENTS"){
							this.init.FXDOCUMENTS= true;
						}
						if(value == "FXNAMESCREEN"){
							this.init.FXNAMESCREEN= true;
						}
						if(value == "FXPENDINGSIGNUPS"){
							this.init.FXPENDINGSIGNUPS= true;
						}
						if(value == "FXSUPERADMIN"){
							this.init.FXSUPERADMIN= true;
						}
						if(value == "FXTRANSACTION"){
							this.init.FXTRANSACTION= true;
						}
						if(value == "FXWATCHLIST"){
							this.init.FXWATCHLIST= true;
						}
						if(value == "FXPENDINGALERTS"){
							this.init.FXPENDINGALERTS= true;
						}

					})
        })
        },
			
            
    },
    beforeMount(){
       this.checkSession();
	  
	},
	 mounted(){
		this.getUserId();
		
	 }
}
</script>

<style lang="scss" scoped>
  .navigation {
    list-style-type: none;
		margin: 0;
		width: 290px !important;
    	min-width: 290px;
		background-color: transparent;
		height: 100%;
		position: fixed;
		top: 0%;
		left: 0%;
		overflow-x: hidden;
		overflow-y: auto;
		padding-top: 16px;
    	z-index: -1;
    

    ul {
      list-style: none;
      margin: 0 !important;
      padding-left: 10px !important;

      li {
        margin-bottom: 16px;
        font-size: 20px;
      }

      a {
        color: black;
        text-decoration: none;

        &:hover, &.router-link-exact-active {
          text-decoration: underline;
        }
      }
    }
  }

  .nav-icon img{
		width: 2rem !important;
		padding: 0px 5px;
		color: black !important;
	}
 
	.header {
	    padding-right: 4em;
	    display: flex;
	    width: 100%;
		padding: 10px;
		
		.logo {
			img {
				width: 100px;
				margin-right: 4em;
				padding: 10px;
			}
		}
	}
.btn-main{
	  background-image: linear-gradient(135deg,#007bff,#007bff 20%,#007bff 70%,#007bff)!important;
		background-size:0% 100%;
		background-repeat:no-repeat;
		transition:.4s;
		width:100%;
		border-radius: 10px;
		padding:5px;
		border: 1px #007bff solid;
		text-align: center !important;
		font-size: 12px;
  }
  .btn-main:hover{
	  background-size:100% 100%;
		color:white !important;
		text-align: center !important;
  }

	.navbar li a {
		display: block;
		color: black;
		padding: 8px 16px;
		text-decoration: none;
		}

	.navbar li a.active {
		background-color: #04AA6D;
		color: black;
		}

	.navbar li a:hover:not(.active) {
		color: #A809CA !important;
		}
	.text-dark{
		color: black;
	}
	.card{
		width: 100%;
		height: auto;
		border: solid 1px transparent;
		background-color: #5F58A1; 
   		 background-image: linear-gradient(#A809CA, #2C0253);
		border-radius: 6px;
		color: white;
    	margin-bottom: 10px;
		
	}
	.show-qr{
		display: flex;
		background:#A809CA;
		margin: 10px;
		border-radius: 4px;
	}
	.clipboard{
		margin: 0px 10px !important;
	}
	.buttons{
		width: 90%;
		display: flex;
		justify-content: space-evenly;

	}
	.buttons button{
		width: 10rem;
		background-color: transparent;
		color: black;
		display: flex;
		justify-content: space-between;
		text-align: center !important;
		padding: 10px;
		border-radius: 17px;
		border: solid 1px lightgray;
		margin-left: 15px;
	}
	.buttons button:hover{
		background-color: #A809CA;
	}
	.button-link{
		background-color: transparent;
		text-decoration: none !important;
		color: black;
	}
	.label{
		padding: 10px;
	}
	.balance{
		display: flex;
		justify-content: space-between;
		color: black;
	}
	.balance .coin{
		width: 2rem;
	}
	.sidebar{
		padding: 15px 0px;
	}
	.footer{
		bottom: 0 !important;
		position: absolute;
		margin-bottom: 30px;
		width: auto !important;
	}
	.footer .btn{
		color: black;
        background-color: #FF8045;
		width: 250px !important;
	}
	
	.wallet-address{
		font-size: 15px;
	}
	.nav-icon img{
		width: 2rem !important;
		padding: 0px 5px;
		color: black !important;
		filter: invert(100%);
	}
	
		

	 @media (min-width: 250px) and (max-width: 500px) {
		 .navigation {
           	width: 200px !important;
            min-width: 250px;
			overflow-y: auto !important;

			ul	{
				
				li{
				font-size: 12px !important;
				margin-top: 10px !important;
				}
			}
         }
		 .card{
			 height: auto;
			 span{
				 font-size: 12px;
			 }
			 
		 }
		 p{
			 font-size: 15px;
		 }
		 .buttons {
          	display: block !important;
         }
		 .btn-main{
			 font-size: 12px;
			 width: 100%;
		 }

	}

     @media (min-width: 500px) and (max-width: 580px) {
           .navigation {
                width: 250px !important;
                min-width: 250px;
				
            }
			.card{
				height: auto;
			 	span{
				 font-size: 15px;
			 }
			 
		 }
		 p{
			 font-size: 15px;
		 }
		 .buttons {
          	display: block !important;
         }
		 .btn-main{
			 font-size: 15px;
			 width: 100%;
		 }


     }

    @media (min-width: 600px) and (max-width: 1024px) {
         .navigation {
           	width: 250px !important;
            min-width: 250px;
         }
         .buttons {
          display: block !important;
         }
		 .card{
				height: auto;
			span{
				 font-size: 20px;
			 }
			 
		 }
		 p{
			 font-size: 20px;
		 }
		 .buttons {
          	display: block !important;
         }
		 .btn-main{
			 font-size: 20px;
			 width: 100%;
		 }
	
    }
    @media only screen and (min-width: 1280) {
      
    
    }

</style>